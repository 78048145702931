.message-group {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 220px);
  width: 100%;
  padding: 40px;
}

.add-btn {
  right: 16px;
  bottom: 16px;
  position: absolute;
}

.atom-header {
  display: flex;
  align-items: center;
}

.dlt-msg {
  position: absolute;
  cursor: pointer;
  top: -10px;
  right: -20px;
  z-index: 5;
}

.MuiGrid-typeItem-135.MuiGrid-grid-xs-12-171.MuiGrid-grid-sm-8-180 {
  width: 60%;
  max-width: 60%;
}

/*.Select-menu-outer {
    top: auto!important;
    bottom: 100%!important;
}*/

.quick-reply {
  border-radius: 35px;
  color: #093A3E;
  outline: none;
  text-align: center;
  margin: 3px;
  border: 1px solid #093A3E;
  background-color: var(--color-white);
  padding: 10px 16px 24px;
  font-size: 14px;
  line-height: 16px;
  resize: none;
  height: 36px;
}

.rep-btns-container {
  background-color: var(--color-white);
  border-radius: 0 0 10px 10px;
}

.button {
  width: 100%;
  color: #093A3E;
  font-weight: 500;
  text-align: center;
  height: 36px;
  font-size: 14px;
  border: none;
  border-bottom: 1px solid var(--color-platinum);
  outline: none;
}

.rangeslider-horizontal .rangeslider__handle:after {
  display: none !important;
}

.rangeslider-horizontal .rangeslider__handle {
  width: 20px !important;
  height: 20px !important;
}

.rangeslider-horizontal {
  height: 10px !important;
}
