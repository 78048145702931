//colors
$white: #ffffff;
$athens-gray: #ebebef;
$ribbon-blue: #1658f3;
$oxford-blue: var(--color-oxford-blue);
$persimmon-red: #ff624c;
$raven-grey: #727a86;
$spun-pearl-grey: #b0b2c0;
$comet-blue: #616581;
$mischka-grey: #d7d8df;
$wild-sand-grey: #f5f5f5;
$mine-shaft-grey: #373E41;
$ghost-grey: var(--color-ghost-grey);
$hawkes-blue: #eef4fe;
$fiord-blue: #093A3E;
$cornflower-blue: #093A3E;
$mine-shaft: #393939;
