.container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.checkedBox {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  backgroundColor: #0B860B;
}

.label {
  color: #616581;
}