body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  text-decoration: none;
}

p,
div,
span,
a {
  font-family: 'Lato', sans-serif;
}

button:focus {
  outline: none !important;
}

a[href^='/admin/bot'] {
  text-decoration: none !important;
}

.Connect-MainMenu--content-11 {
  width: 2% !important;
}

.MuiFormControl-marginNormal-164 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.MuiTypography-body1-81 {
  padding: 0 !important;
  height: 100%;
}

.MuiTypography-body1-386 {
  padding: 0 !important;
  height: 100%;
}

.MuiTypography-body1-418 {
  padding: 0 !important;
  height: 100%;
}

#swipeable > div > div > div {
  padding: 0 !important;
  height: 100%;
}

.main {
  height: 100vh;
  overflow-y: auto;
  width: 100%;
  background: #f7fafe;
}

.row--main {
  background: var(--color-white);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  box-shadow: 4px 4px 29px rgba(19, 69, 186, 0.0855129);
}

.title--small {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  color: var(--color-text-primary);
}

.title--main {
  font-size: 36px;
  font-weight: 700;
  margin: 0 0 16px;
}

.title-section {
  color: var(--color-text-primary);
  height: 100%;
  -webkit-background-size: contain !important;
  background-size: contain !important;
  box-shadow: 0 2px 23px rgba(0, 0, 0, 0.0593014);
  z-index: 1;
}

.title-section .title--small {
  font-size: 16px;
  color: var(--color-text-secondary);
  font-weight: normal;
}

.section__inner {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px 0;
  position: relative;
}

.login-section {
  height: 100%;
  background: var(--color-white);
}

.title-section .section__inner {
  padding: 64px;
  align-items: flex-start;
  justify-content: flex-end;
}

.fa-google-plus-g {
  font-size: 18px;
  font-weight: 700;
}

.button-signin:not(.button-small):not(.button--nostyles):hover {
  transform: scale(1.05);
}

.button-signin {
  background: var(--color-button-primary);
  box-shadow: 0 3px 9px rgba(19, 69, 186, 0.206267);
  border-radius: 10px;
  color: var(--color-white);
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  margin: 8px 0;
  outline: none;
  border: none;
  padding: 16px 24px;
  transition: transform 0.1s ease-in-out;
}

.button--google {
  background: #f34a38;
  box-shadow: 0 3px 9px rgba(211, 72, 54, 0.206267);
  border-radius: 10px;
  color: var(--color-white);
}

.button--google span {
  line-height: 18px;
}

.button--nostyles {
  color: var(--color-text-secondary);
  padding: 0;
  background: transparent;
  margin-top: 0;
}

.button--nostyles:hover {
  text-decoration: underline;
}

.button-small {
  position: absolute;
  bottom: 18px;
  right: 24px;
  font-size: 12px;
  color: var(--color-text-primary);
  margin: 0;
  height: 42px;
  background: var(--color-white);
}

.button-signup {
  margin: 0 0 0 4px;
  color: var(--color-button-primary);
  font-size: 14px;
}

.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
}

.form--login {
  margin: 32px 0;
  text-align: center;
  max-width: 60% !important;
}

.form-input {
  margin: 10px 0 24px;
  border: 1px solid #d7d8df;
  box-sizing: border-box;
  border-radius: 10px;
  padding-left: 24px;
  padding-right: 24px;
  height: 46px;
  outline-color: var(--color-button-primary);
  width: 100%;
}

.form-input::placeholder {
  color: var(--color-spun-pearl-grey);
  font-size: 14px;
  font-weight: 300;
}

.input-label {
  font-size: 14px;
  color: var(--color-text-secondary);
  margin: 0;
  font-weight: 300;
}

.platforms {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  position: absolute;
  bottom: 30px;
}

.platforms-soon {
  margin-left: auto;
}

.platforms__image {
  max-height: 100%;
}

.platforms__title {
  font-size: 10px;
  color: rgba(255, 255, 255, 0.5);
}

.m-l-24px {
  margin-left: 24px;
}

@media (min-width: 992px) {
  .main {
    padding: 50px 90px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .main {
    padding: 30px 60px !important;
  }

  .platforms {
    flex-direction: column;
    justify-content: flex-start;
  }

  .platforms-soon {
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  .row--main {
    height: 100%;
    overflow: hidden;
  }
}

@media (max-width: 767px) {
  main {
  }

  .row--main {
    height: 50%;
  }

  .col-sm-6 {
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

@media (max-width: 576px) {
  .col-sm-10 {
    -ms-flex: 0 0 70% !important;
    flex: 0 0 70% !important;
  }
}

.delay-_05s {
  /*animation-delay: 0.05s;*/
}

.delay-_1s {
  /*animation-delay: 0.1s;*/
}

.delay-_15s {
  /*animation-delay: 0.15s;*/
}

.delay-_2s {
  /*animation-delay: 0.2s;*/
}

.delay-_25s {
  /*animation-delay: 0.25s;*/
}

mark {
  border-radius: 50px !important;
  padding: 1px 8.4px !important;
  background: #093A3E !important;
  font-size: 14px !important;
  font-family: 'Lato', sans-serif;
  color: var(--color-white);
  /*white-space: nowrap;*/
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #9a9fa8;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #9a9fa8;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #9a9fa8;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #9a9fa8;
}

body {
  font-family: Helvetica, sans-serif;
}

.react-autosuggest__container {
  position: relative;
  margin-left: 8px;
}

.react-autosuggest__input {
  width: 130px;
  height: 38px;
  padding: 2px 8px;
  font-family: Helvetica, sans-serif;
  font-weight: 400;
  font-size: 14px;
  border: 1px solid hsl(0, 0%, 90%);
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 40px;
  box-shadow: 0 4px 8px rgba(53, 64, 82, 0.32);
  width: 130px;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  z-index: 2;
  border-radius: 4px;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 8px 12px;
  font-size: 14px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: rgba(90, 152, 247, 0.1);
}

.footer {
  margin: 9px 20px;
  font-size: 12px;
  color: #777;
}
